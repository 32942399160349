import { useState, createContext, useRef } from "react";
import { Modal, App } from "antd";

export const TipsContext = createContext({});

// 弹窗提醒
const Tips = ({ children }) => {
  // 因为 Antd 提供的App.useApp中的Modal方法不支持传输大文本 所以自己封装
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const _initModal = () => {
    setShowModal(false);
    setModalMessage("");
  };
  const handleOkRef = useRef(_initModal);

  const { message } = App.useApp();

  return (
    <TipsContext.Provider
      value={{
        useModal: () => {
          const modal = (msg = { title: "", content: "" }, cb = () => {}) => {
            setModalMessage(msg.content);
            setModalTitle(msg.title);
            setShowModal(true);
            handleOkRef.current = () => {
              cb();
              _initModal();
            };
          };
          return {
            modal,
          };
        },
        useMessage: message,
      }}
    >
      <Modal
        title={modalTitle}
        open={showModal}
        onOk={handleOkRef.current}
        onCancel={_initModal}
        zIndex={9999}
      >
        {modalMessage}
      </Modal>
      {children}
    </TipsContext.Provider>
  );
};

export default ({ children }) => {
  return (
    // 封装了Antd的提示样式 https://ant-design.antgroup.com/components/app-cn
    <App>
      <Tips>{children}</Tips>
    </App>
  );
};
