import React from "react";
import { InfinityScroll, HotArea } from "@/components";

export default function LoopScrollPreview({
  baseConfig,
  props,
  rectOptions,
  zIndex = 0,
}) {
  const {
    scrollList,
    between,
    scrollItemWidth,
    scrollItemHeight,
    scrollWrapWidth,
    speed,
    direction,
  } = props;
  const { left, top } = rectOptions;
  return (
    <div
      style={{
        width: scrollWrapWidth,
        height: scrollItemHeight,
        overflow: "scroll",
        position: "absolute",
        left,
        top,
        zIndex,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <InfinityScroll
        windowSize={{
          width: scrollWrapWidth,
          height: scrollItemHeight,
        }}
        speed={speed * 10}
        direction={Number(direction) === 0 ? "left" : "right"}
      >
        {scrollList.map((item, index) => {
          return (
            <div
              key={index + "loop-scroll"}
              style={{
                position: "relative",
                width: scrollItemWidth,
                height: scrollItemHeight,
                marginLeft: between,
              }}
            >
              <img
                style={{
                  width: scrollItemWidth,
                  height: scrollItemHeight,
                }}
                draggable={false}
                src={item.imageUrl}
                alt=""
              />
              {item.hotArea.map((hot, hotIndex) => {
                return <HotArea hot={hot} key={hotIndex} />;
              })}
            </div>
          );
        })}
      </InfinityScroll>
    </div>
  );
}
