import React, { useContext, useState, useCallback, useRef } from "react";
import _ from "lodash";
import { Layout, Space, Button } from "antd";
import { ToolOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { DrawPageStateContext, HotAreaConfigContext } from "@/context";
import {
  ConfigTitle,
  Input,
  Radio,
  Slider,
  ColorPicker,
  BaseButton,
  MoveAble,
} from "@/components";
import { InputNumber3x } from "@/HOC";
import "./index.less";

const { Header, Sider, Content } = Layout;

// 热区编辑界面
export default function HotAreaEditContent() {
  const { setShowHotEdit } = useContext(DrawPageStateContext);
  const { contentSize, content, onSave, defaultValue, contentFit } =
    useContext(HotAreaConfigContext);
  const [hotAreaList, setHotAreaList] = useState(defaultValue);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragMoveAbleLock, setDragMoveAbleLock] = useState(false);
  const priceElemList = useRef([]);

  console.log(hotAreaList, "log hot area list");

  const updateHotAreaValue = useCallback(
    (v, index) => {
      const newList = [...hotAreaList];
      Object.keys(v).forEach((k) => {
        if (newList[index][k] !== undefined) {
          newList[index][k] = v[k];
        }
      });
      setHotAreaList(newList);
    },
    [hotAreaList]
  );

  const updateHotAreaPrice = useCallback(
    (v, index) => {
      const newList = _.cloneDeep(hotAreaList);
      Object.keys(v).forEach((k) => {
        if (newList[index]["priceConfig"][k] !== undefined) {
          newList[index]["priceConfig"][k] = v[k];
        }
      });
      setHotAreaList(newList);
    },
    [hotAreaList]
  );

  const deleteHotArea = useCallback(
    (index) => {
      const newList = [...hotAreaList];
      newList.splice(index, 1);

      setHotAreaList(newList);

      if (index === currentIndex) {
        setCurrentIndex(0);
      }
    },
    [hotAreaList]
  );

  return (
    <div className="highest-layer hot-area-edit-wrap">
      <Layout
        style={{
          width: "100%",
          height: "100%",
          background: "transparent",
        }}
      >
        <Sider
          width="208"
          style={{
            backgroundColor: "rgba(105, 105, 105, 0.64)",
          }}
        />
        <Layout>
          <Header
            style={{
              backgroundColor: "#fff",
              height: 60,
              borderBottom: "1px solid #d4d4d4",
              padding: "0 20px",
            }}
            className="justify-between"
          >
            <Space style={{ color: "#3658EC" }}>
              <ToolOutlined />
              热区编辑器
            </Space>
            <Space size={13}>
              <Button
                style={{ width: 88 }}
                type="primary"
                onClick={() => {
                  onSave(_.cloneDeep(hotAreaList));
                  setShowHotEdit(false);
                }}
              >
                保存
              </Button>
              <Button
                style={{ width: 88 }}
                onClick={() => {
                  setShowHotEdit(false);
                }}
              >
                返回
              </Button>
            </Space>
          </Header>
          <Layout hasSider>
            <Content
              style={{
                flex: 1,
                padding: "87px 76px",
                backgroundColor: "#F1F3F4",
                display: "flex",
                justifyContent: "center",
                overflow: "scroll",
              }}
            >
              <div
                className="hot-area-target"
                style={{
                  width: contentSize.width,
                  height: contentSize.height,
                  position: "relative",
                  border: "1px solid black",
                }}
              >
                <img
                  src={content}
                  alt=""
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: contentFit,
                  }}
                />
                {hotAreaList.map((h, index) => (
                  <MoveAble
                    key={"moveable-hot-area" + index}
                    maxWidth={contentSize.width}
                    maxHeight={contentSize.height}
                    isActive={currentIndex === index}
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                    onResizeStart={() => {
                      setCurrentIndex(index);
                    }}
                    onDragStart={() => {
                      setCurrentIndex(index);
                    }}
                    onResize={({ width, height, left, top }) => {
                      updateHotAreaValue({ width, height, left, top }, index);
                      if (width < h.priceConfig.left + h.priceConfig.width) {
                        updateHotAreaPrice(
                          { left: Math.max(0, width - h.priceConfig.width) },
                          index
                        );
                      }
                      if (height < h.priceConfig.top + h.priceConfig.height) {
                        updateHotAreaPrice(
                          {
                            top: Math.max(0, height - h.priceConfig.height),
                          },
                          index
                        );
                      }
                    }}
                    drag={!dragMoveAbleLock}
                    onDrag={({ top, left }) => {
                      updateHotAreaValue({ top, left }, index);
                    }}
                  >
                    <div
                      className="hot-item"
                      style={{
                        width: h.width,
                        height: h.height,
                        transform: `translate(${h.left}px, ${h.top}px)`,
                      }}
                    >
                      {h.showPrice && (
                        <MoveAble
                          maxWidth={h.width}
                          maxHeight={h.height}
                          showPoint={false}
                          showOrigin={false}
                          showBorder={false}
                          resize={false}
                          onClick={() => {
                            setCurrentIndex(index);
                          }}
                          onDragStart={() => {
                            setCurrentIndex(index);
                          }}
                          onDrag={_.throttle(({ top, left, width, height }) => {
                            updateHotAreaPrice(
                              { top, left, width, height },
                              index
                            );
                          }, 50)}
                        >
                          <div
                            style={{
                              fontSize: h.priceConfig.fontSize,
                              fontWeight: h.priceConfig.fontWeight,
                              color: h.priceConfig.color,
                              position: "absolute",
                              transform: `translate(${h.priceConfig.left}px, ${h.priceConfig.top}px)`,
                            }}
                            className="price"
                            onMouseEnter={() => {
                              setDragMoveAbleLock(true);
                            }}
                            onMouseLeave={() => {
                              setDragMoveAbleLock(false);
                            }}
                          >
                            {h.priceConfig.showPrefix === true ? "￥" : ""}
                            {h.priceConfig.useInputPrice
                              ? Number(h.priceConfig.inputPrice).toFixed(
                                  h.priceConfig.priceTofixed
                                )
                              : 100}
                          </div>
                        </MoveAble>
                      )}
                    </div>
                  </MoveAble>
                ))}
              </div>
            </Content>
            <Sider
              style={{
                overflow: "scroll",
                backgroundColor: "#fff",
              }}
              width="360"
            >
              <ConfigTitle
                title="热区设置"
                detail="点击新增热区，在左侧拖拽调整"
                style={{
                  padding: "20px 0 5px 20px",
                }}
              />
              {hotAreaList.map((hotArea, index) => {
                return (
                  <div
                    key={"hot-area" + index}
                    className="hot-area-config-module"
                  >
                    <Space direction="vertical" size={10}>
                      <Space size={218}>
                        <ConfigTitle
                          title={`热区${index + 1}`}
                          titleSize={14}
                          style={{
                            height: "auto",
                          }}
                        />
                        <Space
                          size={2}
                          style={{
                            color: "#655FE1",
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            deleteHotArea(index);
                          }}
                        >
                          <DeleteOutlined />
                          删除热区
                        </Space>
                      </Space>

                      <Input
                        labelContent="跳转链接"
                        defaultValue={hotArea.jumpLink}
                        onChange={(v) => {
                          updateHotAreaValue(
                            {
                              jumpLink: v,
                            },
                            index
                          );
                        }}
                      />
                      <Space size={10}>
                        <InputNumber3x
                          labelContent="宽"
                          size="middle"
                          defaultValue={hotArea.width}
                          style={{
                            color: "#696969",
                          }}
                          onChange={(v) => {
                            updateHotAreaValue(
                              {
                                width: v,
                              },
                              index
                            );
                          }}
                        />
                        <InputNumber3x
                          labelContent="高"
                          size="middle"
                          defaultValue={hotArea.height}
                          style={{
                            color: "#696969",
                          }}
                          onChange={(v) => {
                            updateHotAreaValue(
                              {
                                height: v,
                              },
                              index
                            );
                          }}
                        />
                      </Space>
                      <Space size={10}>
                        <InputNumber3x
                          labelContent="上边距"
                          size="middle"
                          style={{
                            color: "#696969",
                          }}
                          defaultValue={hotArea.top}
                          onChange={(v) => {
                            updateHotAreaValue(
                              {
                                top: v,
                              },
                              index
                            );
                          }}
                        />
                        <InputNumber3x
                          labelContent="左边距"
                          size="middle"
                          style={{
                            color: "#696969",
                          }}
                          defaultValue={hotArea.left}
                          onChange={(v) => {
                            updateHotAreaValue(
                              {
                                left: v,
                              },
                              index
                            );
                          }}
                        />
                      </Space>
                      <Radio
                        title="是否显示价格:"
                        radioList={["显示", "不显示"]}
                        defaultValue={hotArea.showPrice ? 0 : 1}
                        onChange={(v) => {
                          updateHotAreaValue(
                            {
                              showPrice: v === 0,
                            },
                            index
                          );
                        }}
                      />
                      {hotArea.showPrice && (
                        <>
                          <ConfigTitle
                            title="价格1文本设置"
                            titleSize={14}
                            style={{
                              height: "auto",
                            }}
                          />
                          <Space size={10}>
                            <InputNumber3x
                              labelContent="上边距"
                              size="middle"
                              defaultValue={hotArea.priceConfig.top}
                              style={{
                                color: "#696969",
                              }}
                              onChange={(v) => {
                                // 文字上边距
                                updateHotAreaPrice(
                                  {
                                    top: v,
                                  },
                                  index
                                );
                              }}
                            />
                            <InputNumber3x
                              labelContent="左边距"
                              size="middle"
                              style={{
                                color: "#696969",
                              }}
                              defaultValue={hotArea.priceConfig.left}
                              onChange={(v) => {
                                // 文字左边距
                                updateHotAreaPrice(
                                  {
                                    left: v,
                                  },
                                  index
                                );
                              }}
                            />
                          </Space>
                          <Slider
                            title="文字大小"
                            min={12}
                            max={100}
                            defaultValue={hotArea.priceConfig.fontSize}
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  fontSize: v,
                                },
                                index
                              );
                            }}
                            suffix="px"
                          />

                          <Slider
                            title="文字粗细(受京东字体限制，实际效果以店铺后台为准)"
                            min={100}
                            max={900}
                            step={100}
                            defaultValue={hotArea.priceConfig.fontWeight}
                            suffix=""
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  fontWeight: v,
                                },
                                index
                              );
                            }}
                          />
                          <ColorPicker
                            title="文字颜色"
                            defaultValue={hotArea.priceConfig.color}
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  color: v,
                                },
                                index
                              );
                            }}
                          />
                          <Radio
                            title="是否显示价格符号:"
                            radioList={["显示", "不显示"]}
                            defaultValue={
                              hotArea.priceConfig.showPrefix ? 0 : 1
                            }
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  showPrefix: v === 0,
                                },
                                index
                              );
                            }}
                          />
                          <Radio
                            title="是否使用输入价格:"
                            radioList={["输入价格", "抓取价格"]}
                            defaultValue={
                              hotArea.priceConfig.useInputPrice ? 0 : 1
                            }
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  useInputPrice: v === 0,
                                },
                                index
                              );
                            }}
                          />
                          <Radio
                            title="保留几位小数:"
                            radioList={[0, 1, 2]}
                            defaultValue={hotArea.priceConfig.priceTofixed}
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  priceTofixed: v,
                                },
                                index
                              );
                            }}
                          />
                          <Input
                            labelContent="输入价格"
                            defaultValue={hotArea.priceConfig.inputPrice}
                            onChange={(v) => {
                              updateHotAreaPrice(
                                {
                                  inputPrice: v,
                                },
                                index
                              );
                            }}
                          />
                        </>
                      )}
                    </Space>
                  </div>
                );
              })}

              <div
                className="justify-start"
                style={{
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <BaseButton
                  content="新增热区"
                  type="normal"
                  icon={<PlusOutlined />}
                  style={{
                    fontSize: 12,
                    width: 155,
                  }}
                  onClick={() => {
                    const newList = [...hotAreaList];
                    newList.push({
                      width: 100,
                      height: 100,
                      top: 0,
                      left: 0,
                      jumpLink: "",
                      showPrice: false,
                      priceConfig: {
                        width: 32,
                        height: 22,
                        left: 0,
                        top: 0,
                        color: "#000",
                        fontSize: 16,
                        fontWeight: 400,
                        showPrefix: true,
                        useInputPrice: true,
                        inputPrice: "",
                        priceTofixed: 0,
                      },
                    });
                    setHotAreaList(newList);
                    priceElemList.current.push(null);
                  }}
                />
              </div>
            </Sider>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
