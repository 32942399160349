import React from "react";
import "./index.less";

export default function HotArea({ hot, style = {}, catchPrice = 45 }) {
  return (
    <div
      style={Object.assign(
        {
          width: hot.width,
          height: hot.height,
          position: "absolute",
          left: hot.left,
          top: hot.top,
        },
        style
      )}
      onClick={() => {
        window.open(hot.jumpLink);
      }}
      className="hot-area"
    >
      {hot.showPrice && (
        <div
          style={{
            color: hot.priceConfig.color,
            fontSize: hot.priceConfig.fontSize,
            fontWeight: hot.priceConfig.fontWeight,
            position: "absolute",
            left: hot.priceConfig.left,
            top: hot.priceConfig.top,
            lineHeight: "normal",
          }}
        >
          {hot.priceConfig.showPrefix ? "￥" : ""}
          {hot.priceConfig.useInputPrice
            ? Number(hot.priceConfig.inputPrice).toFixed(
                hot.priceConfig.priceTofixed
              )
            : Number(catchPrice).toFixed(hot.priceConfig.priceTofixed)}
        </div>
      )}
    </div>
  );
}
