import React, { useState } from "react";
import { HotArea } from "@/components";

export default function ExpandHidePreview({ rectOptions, props, zIndex = 0 }) {
  const { showImgUrl, hideImgUrl, jumpUrl, subProps } = props;
  const { height, subRectOptions } = rectOptions;
  const { banners, imageWidth, imageHeight } = subProps[0];
  const expandHeight = subRectOptions[0].height;
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: isExpand ? height + expandHeight : height,
        overflow: "hidden",
        position: "absolute",
        transition: "height 300ms",
        zIndex,
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          transform: `translateY(${isExpand ? "0%" : "-100%"})`,
          transition: "300ms",
          width: imageWidth,
          height: expandHeight,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {banners.map((banner, index) => {
          return (
            <div
              style={{
                position: "relative",
                width: imageWidth,
                height: imageHeight,
              }}
            >
              <img
                src={banner.imageUrl}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              {banner.hotArea.map((hot, hotIndex) => {
                return <HotArea hot={hot} key={hotIndex} />;
              })}
            </div>
          );
        })}
      </div>
      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height,
        }}
        src={isExpand ? hideImgUrl : showImgUrl}
        alt=""
        onClick={() => {
          setIsExpand(!isExpand);
        }}
      />
    </div>
  );
}
