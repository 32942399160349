import React, { useRef, useEffect } from "react";
import "./index.less";
import { HotArea } from "@/components";

export default function LoopScrollPreview({ props, rectOptions, zIndex = 0 }) {
  const {
    scrollList,
    between,
    scrollItemWidth,
    scrollItemHeight,
    scrollWrapWidth,
    subProps,
  } = props;
  const { left, top, subRectOptions } = rectOptions;
  const { radius, defaultColor, activeColor } = subProps[0];
  const subLeft = subRectOptions[0].left;
  const subTop = subRectOptions[0].top;
  const subWidth = subRectOptions[0].width;
  const subHeight = subRectOptions[0].height;

  const scrollRef = useRef(null);
  const dotRef = useRef(null);

  const videoRef = useRef(null);

  useEffect(() => {
    const contentWidth =
      scrollList.length * (scrollItemWidth + between) - scrollWrapWidth;
    const radio = contentWidth / subWidth;
    const handleScroll = (e) => {
      dotRef.current.style.transform = `translateX(${
        -subWidth + e.target.scrollLeft / radio
      }px)`;
    };
    scrollRef.current.addEventListener("scroll", handleScroll, false);

    videoRef.current.play();
    return () => {
      scrollRef.current.removeEventListener("scroll", handleScroll, false);
    };
  }, []);
  return (
    <>
      <div
        style={{
          width: scrollWrapWidth,
          height: scrollItemHeight,
          overflow: "scroll",
          position: "absolute",
          left,
          top,
          zIndex,
          display: "flex",
          justifyContent: "flex-start",
        }}
        ref={scrollRef}
      >
        {scrollList.map((item, index) => {
          return (
            <div
              key={index + "loop-scroll"}
              style={{
                flexShrink: 0,
                position: "relative",
                width: scrollItemWidth,
                height: scrollItemHeight,
                marginLeft: between,
              }}
            >
              {item.mediaUrl.indexOf(".mp4") > -1 ? (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <video
                    src={item.mediaUrl}
                    // muted={isMuted}
                    muted="muted"
                    loop
                    autoPlay
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    poster={item.poster}
                    ref={videoRef}
                  ></video>
                  <img
                    src={item.maskUrl}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                </div>
              ) : (
                <img
                  src={item.mediaUrl}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {item.hotArea.map((hot, hotIndex) => {
                return <HotArea hot={hot} key={hotIndex} />;
              })}
            </div>
          );
        })}
      </div>
      <div
        style={{
          position: "absolute",
          width: subWidth,
          height: subHeight,
          overflow: "hidden",
          borderRadius: radius,
          backgroundColor: defaultColor,
          left: subLeft,
          zIndex,
          top: subTop,
          // transform: `translate(${left}px, ${top}px)`,
        }}
      >
        <div
          ref={dotRef}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: activeColor,
            borderRadius: radius,
            transform: `translateX(-${subWidth}px)`,
          }}
        ></div>
      </div>
    </>
  );
}
